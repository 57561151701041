import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { currentLocationUpdate } from "@actions"

export const useCurrentLocation = () => {
  const dispatch = useDispatch()

  const [currentLocation, setCurrentLocation] = useState({})
  const [error, setError] = useState(null)

  const onChange = ({ coords }) => {
    setCurrentLocation({
      latitude: coords.latitude,
      longitude: coords.longitude,
    })
    // Save in Redux
    dispatch(currentLocationUpdate({ currentLocation: {
      latitude: coords.latitude,
      longitude: coords.longitude,
    }}))
  }

  const onError = error => {
    setError(error.message)
  }

  useEffect(() => {
    const geo = navigator.geolocation

    if (!geo) {
      // TODO: Add to internationalisation
      setError("Geolocation is not supported")
      return
    }

    const watcher = geo.watchPosition(onChange, onError)
    return () => geo.clearWatch(watcher)
  }, [])

  return [currentLocation, error]
}
