// To add or not to add the trailing slash, that is the question

export const HOME = "/"
export const USER = "/:username/"
export const TATTOOS = "/tattoos/"
export const TATTOO = "/tattoos/:id/"
export const ARTISTS = "/artists/"
export const STUDIOS = "/studios/"
export const ARTICLES = "/articles/"
export const ARTICLE = "/articles/:slug/"
export const APP = "/app/"
export const FAQS = "/faqs/"
export const TERMS = "/terms/"
export const PRIVACY = "/privacy/"
export const TEAM = "/join-the-team/"
export const SELL = "/sell-on-iynk/"
export const ADD = "/add-your-studio/"
export const BOOK_A_TATTOO = "/book-a-tattoo/"
// Admin
export const STATS = "/stats/"
// Authenticated
export const ACCOUNT = "/account/"
export const BOOK = "/book/"
export const ACTIVITIES = "/activities/"
export const BOOKINGS = "/bookings/"
export const BOOKING = "/bookings/:id/"
export const CHANGE_PASSWORD = "/changepassword/"
// Not Found
export const NOT_FOUND = "/404"
