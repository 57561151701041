import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.iynk.iynk&hl=en_GB"

const DownloadGoogle = ({ alt, style, imgStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "download-on-google-play.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <a href={ANDROID_URL} target="_blank" rel="noopener noreferrer">
      <Img
        fluid={data.img.childImageSharp.fluid}
        style={style} /* styles for the wrapper container */
        imgStyle={imgStyle} /* Styles for the actual img element */
        alt={alt}
      />
    </a>
  )
}

DownloadGoogle.defaultProps = {
  style: {},
  imgStyle: {},
  alt: "Download IYNK on Google",
}

DownloadGoogle.propTypes = {
  style: PropTypes.object.isRequired,
  imgStyle: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default DownloadGoogle
