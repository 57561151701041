import { useEffect, useState } from "react"
import { get, set } from "lodash"

import { useFirebase } from "@hooks"

export const useMessages = booking => {
  // const reading = useSelector(selectReading);
  const [messages, setMessages] = useState([])
  const [users, setUsers] = useState({})
  const firebase = useFirebase()

  // TODO: Could DRY into own component
  const getReference = async ref => {
    const doc = await ref.get()
    if (!doc.exists) return
    return { id: doc.id, ...doc.data() }
  }

  // TODO: Could DRY into own component
  const populate = async (document, paths = []) =>
    Promise.all(
      paths.map(async path => {
        const id = get(document, path)
        if (!id || typeof id !== "string") return
        const ref = firebase.firestore.collection("users").doc(id)
        // Load from state if already loaded?
        const result = await getReference(ref)
        // Only load the bits we need
        set(document, path, {
          id: result.id,
          photoUrl: result.photoUrl,
          username: result.username,
          name: result.name,
        })
      })
    )

  const onSuccess = async snapshot => {
    try {
      const messages = []
      for (const doc of snapshot.docs) {
        const message = { ...doc.data(), id: doc.id }
        await populate(message, ["sender", "receiver"])
        messages.push(message)
      }
      setMessages(messages)
      // if (reading) {
      //   dispatch(messagesRead({ bookingId: booking.id }));
      // }
    } catch (e) {
      console.log("Error in onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in useBooking", e)
  }

  useEffect(() => {
    if (!firebase) return
    const unsubscribe = firebase.firestore
      .collection("bookings")
      .doc(booking.id)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .onSnapshot(onSuccess, onError)
    firebase.subscriptions.push(unsubscribe)

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [firebase]) // , reading

  return messages
}
