import { useEffect, useState } from "react"
import { get, set } from "lodash"

import {
  USERS_ROLE_ARTIST,
  USERS_ROLE_CUSTOMER,
  USERS_ROLE_STUDIO,
} from "@constants"

import { useFirebase } from "./useFirebase"

export const useBooking = id => {
  const [booking, setBooking] = useState({})
  const firebase = useFirebase()

  // TODO: Could DRY into own component
  const getReference = async ref => {
    const doc = await ref.get()
    if (!doc.exists) return
    return { id: doc.id, ...doc.data() }
  }

  // TODO: Could DRY into own component
  const populate = async (document, paths = []) =>
    Promise.all(
      paths.map(async path => {
        const id = get(document, path)
        if (!id || typeof id !== "string") return
        const ref = firebase.firestore.collection("users").doc(id)
        const result = await getReference(ref)
        set(document, path, result)
      })
    )

  const onSuccess = async doc => {
    try {
      if (doc.exists) {
        const booking: any = { ...doc.data(), id: doc.id }
        await populate(booking, [
          USERS_ROLE_ARTIST,
          USERS_ROLE_CUSTOMER,
          USERS_ROLE_STUDIO,
        ])
        setBooking(booking)
      }
    } catch (e) {
      console.log("Error in useBooking onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in useBooking onError", e)
  }

  useEffect(() => {
    if (!firebase || !id) return

    const unsubscribe = firebase.firestore
      .collection("bookings")
      .doc(id)
      .onSnapshot(onSuccess, onError)
    firebase.subscriptions.push(unsubscribe)

    return () => {
      if (unsubscribe) return unsubscribe()
    }
  }, [firebase, id])

  return booking
}
