import { useCallback, useState } from "react"
import { mergeDeepRight } from "ramda"
import { useSelector, useDispatch } from "react-redux"

import { useFirebase } from "./useFirebase"
import { activitiesIndex } from "@actions"
import { selectActivities } from "@selectors"

export const useActivities = () => {
  // TODO: Move to selector
  const activitiesFromState = useSelector(selectActivities) || {}
  const dispatch = useDispatch()
  const [activities, setActivities] = useState(activitiesFromState)
  const firebase = useFirebase()

  const onSuccess = async snapshot => {
    try {
      const activitiesLocal = []
      for (const doc of snapshot.docs) {
        const activity: any = { ...doc.data(), id: doc.id }
        activitiesLocal[activity.id] = activity
      }
      const updatedActivites = mergeDeepRight(activities, activitiesLocal)
      setActivities(updatedActivites)
      dispatch(activitiesIndex({ activities: updatedActivites }))
    } catch (e) {
      console.log("Error in useActivities onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in useActivities onError", e)
  }

  // Error in useActivities onError FirebaseError: Missing or insufficient permissions.
  const activitiesSubscription = useCallback(
    user => {
      if (!firebase || !user?.uid) return

      const unsubscribe = firebase.firestore
        .collection("activities")
        .where("user", "==", user.uid)
        .onSnapshot(onSuccess, onError)
      firebase.subscriptions.push(unsubscribe)

      // return () => {
      //   if (unsubscribe) return unsubscribe()
      // }
    },
    [firebase]
  )

  return activitiesSubscription
}
