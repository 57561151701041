import firebase from "gatsby-plugin-firebase"

import { FUNCTIONS_REGION, FIREBASE_PROJECT_ID } from "@constants"

class Firebase {
  constructor() {
    /* Helper */

    this.fieldValue = firebase.firestore.FieldValue
    this.emailAuthProvider = firebase.auth.EmailAuthProvider

    /* Firebase APIs */

    this.auth = firebase.auth()
    this.firestore = firebase.firestore()

    /* Social Sign In Method Provider */

    this.facebookProvider = new firebase.auth.FacebookAuthProvider()

    /* Subscriptions to clear on logout */
    this.subscriptions = []

    this.FUNCTIONS_URL = `https://${FUNCTIONS_REGION}-${FIREBASE_PROJECT_ID}.cloudfunctions.net/app`

    this.ACCESS_TOKEN = null
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  unsubscribe = () => {
    this.subscriptions.forEach(unsubscribe => {
      if (typeof unsubscribe === "function") {
        unsubscribe()
      }
    })
  }

  doSignOut = () => {
    this.auth.signOut()
    this.unsubscribe()
  }

  // Replace with API
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  // Replace with API
  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    })

  // Replace with API
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        // Could fetch user here
        next(authUser)
      } else {
        fallback()
      }
    })

  // *** User API ***

  get functionsUrl() {
    return this.FUNCTIONS_URL
  }

  get accessToken() {
    return this.ACCESS_TOKEN
  }
}

function getFirebase() {
  return new Firebase()
}

export default getFirebase
