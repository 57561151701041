import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import classnames from "classnames"

import { useAuth } from "@hooks"
import { selectCurrentUser } from "@selectors"
import Header from "@components/Header"
import Footer from "@components/Footer"
import LoginRegister from "@components/LoginRegister"
import CookieConsent from "@components/CookieConsent"
import GlobalContext from "./context"

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // Load currentUser from db
  useAuth()
  const currentUser = useSelector(selectCurrentUser)

  // Login-Modal related
  const [show, setShow] = useState(false)
  const [login, setLogin] = useState(false)

  const loginModal = e => {
    e.stopPropagation()
    e.preventDefault()
    setLogin(true)
    setShow(true)
  }

  const registerModal = e => {
    e.stopPropagation()
    e.preventDefault()
    setLogin(false)
    setShow(true)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: "no-js has-background-black", // Removed after load
        }}
        bodyAttributes={{
          class: classnames("has-navbar-fixed-top", "has-background-white", {
            "no-scroll": show,
          }),
        }}
      />
      <GlobalContext.Provider
        value={{ currentUser, loginModal, registerModal }}
      >
        <div
          className={classnames("site", {
            ...(className && typeof className === "object"
              ? className
              : className && { [className]: true }),
          })}
        >
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className="site-content">{children}</main>
          <Footer />
        </div>
        {!currentUser && (
          <LoginRegister
            show={show}
            setShow={setShow}
            login={login}
            setLogin={setLogin}
          />
        )}
      </GlobalContext.Provider>
      <CookieConsent />
    </>
  )
}

export { GlobalContext }

Layout.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Layout
