import { useState, useCallback } from "react"

import axios from "@lib/axios"

export const useLazyQuery = (
  action,
  payload = {},
  { onCompleted = data => {}, onError = e => {} } = {}
) => {
  const [state, setState] = useState({
    called: false,
    data: null,
    error: null,
    loading: false,
  })

  const query = useCallback(() => {
    setState({ ...state, loading: true })
    axios
      .post(`${process.env.API_URL}${action}`, payload)
      .then(response => {
        setState({
          called: true,
          data: response.data,
          error: null,
          loading: false,
        })
        onCompleted(response.data)
      })
      .catch(e => {
        console.log(e)
        setState({ ...state, called: true, error: e, loading: false })
        onError(e)
      })
  }, [payload])

  const { data, error, loading } = state
  return [query, { data, error, loading }]
}
