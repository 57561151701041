import { useEffect, useState } from "react"

import axios from "@lib/axios"

export const useQuery = (
  action,
  payload = {},
  { onCompleted = data => {}, onError = error => {}, onUnmount = () => {} } = {}
) => {
  const [state, setState] = useState({
    data: null,
    error: null,
    loading: false,
  })

  useEffect(() => {
    setState({ ...state, loading: true })
    axios
      .post(`${process.env.GATSBY_API_URL}${action}`, payload)
      .then(response => {
        setState({ data: response.data, error: null, loading: false })
        onCompleted(response.data)
      })
      .catch(e => {
        console.log(e)
        setState({ ...state, error: e, loading: false })
        onError(e)
      })
    return () => onUnmount()
  }, [])

  return state
}
