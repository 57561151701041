import qs from "qs"

// window.history.replaceState(null, null, null)

// - `stateObj` - The state object is a JavaScript object which is associated with the history entry passed to the replaceState method. The state object can be null.
// - `title` - Most browsers currently ignore this parameter, although they may use it in the future. Passing the empty string here should be safe against future changes to the method. Alternatively, you could pass a short title for the state.
// - `url` - Optional: The URL of the history entry. The new URL must be of the same origin as the current URL; otherwise replaceState throws an exception.

export const updateQuery = query => {
  if (!query) {
    // Remove URL parameters
    return window.history.replaceState(
      null,
      null,
      "/" +
        window.location.href
          .substring(window.location.href.lastIndexOf("/") + 1)
          .split("?")[0]
    )
  }

  window.history.replaceState(null, null, `?query=${query}`)

  // window.history.replaceState(
  //   null,
  //   null,
  //   filter && filter.length
  //     ? `?query=${encodeURIComponent(query)}&filter=${encodeURIComponent(
  //         filter
  //       ).replace(/%20/g, "+")}`
  //     : query
  //     ? `?query=${encodeURIComponent(query)}`
  //     : "?"
  // )
}

export const getSearch = () => {
  let currentSearch = new URLSearchParams(window.location.search)
  let parameters = {
    query: currentSearch.get("query"),
    filter: currentSearch.get("filter"),
  }
  return parameters
}

// Pagination

export const DEBOUNCE_TIME = 700

export const createURL = state => `?${qs.stringify(state)}`

export const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : ""

export const urlToSearchState = location => {
  return qs.parse(location.search.slice(1))
}
