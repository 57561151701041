import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faSearch,
  faMapMarkerAlt,
  faHeart,
  faComment,
  faShareAlt,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faList,
  faPlus,
  faMinus,
  faTimes,
  faArrowLeft,
  faEnvelope,
  faSlidersH,
  faCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons"
import {
  faCheckCircle as faCheckCircleRegular,
  faMap,
  faStar as faStarRegular
} from "@fortawesome/free-regular-svg-icons"

library.add(
  fab,
  faSearch,
  faMapMarkerAlt,
  faHeart,
  faComment,
  faShareAlt,
  faCheckCircleRegular,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faMap,
  faList,
  faPlus,
  faMinus,
  faTimes,
  faArrowLeft,
  faEnvelope,
  faSlidersH,
  faCircle,
  faStar,
  faStarRegular
)
