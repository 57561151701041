import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
// import { useLocation } from "@reach/router"
import { useSiteMetadata } from "@hooks"

function SEO({ description, lang, meta, title, titleTemplate }) {
  // let { pathname } = useLocation()
  const { site } = useSiteMetadata()

  const metaDescription = description || site.siteMetadata.description

  // Remove trailingSlash
  // pathname = pathname.replace(/\/+$/, "")
  // const cannonicalUrl = `${site.siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        titleTemplate ? `%s | ${site.siteMetadata.title}` : undefined
      }
      defaultTitle={site.siteMetadata.title}
      // link={[{ rel: "canonical", key: cannonicalUrl, href: cannonicalUrl }]}
      meta={[
        {
          "http-equiv": `Content-type`,
          content: `text/html;charset=UTF-8`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        // {
        //   property: `og:url`,
        //   content: cannonicalUrl,
        // },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: `stylesheet`,
          type: `text/css`,
          charSet: `UTF-8`,
          href: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css`,
        },
        {
          rel: `stylesheet`,
          type: `text/css`,
          href: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css`,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  titleTemplate: true,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default SEO
