import { useMemo } from "react"
import algoliasearch from "algoliasearch" // Was algoliasearch/lite

export const useAlgolia = () => {
  // useMemo prevents the searchClient from being recreated on every render.
  // Avoids unnecessary XHR requests (see https://tinyurl.com/yyj93r2s).
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_API_KEY
        // Provided by Algolia for testing...
        // "latency",
        // "6be0576ff61c053d5f9a3225e2a90f76"
      ),
    []
  )
  return searchClient
}
