import { useEffect, useState } from "react"

import { useFirebase } from "./useFirebase"

export const useComments = photo => {
  const [comments, setComments] = useState([])
  const firebase = useFirebase()

  const onSuccess = snapshot => {
    try {
      const comments = []
      for (const doc of snapshot.docs) {
        const c = doc.data()
        const comment = {
          _id: doc.id, // id is used for normalizr
          createdAt: c.createdAt,
          id: doc.id,
          text: c.comment,
          user: {
            // Catch for old author data
            _id: (c.user && c.user.id) || (c.author && c.author.id),
            avatar:
              (c.user && c.user.photoUrl) || (c.author && c.author.photoUrl),
            username:
              (c.user && c.user.username) || (c.author && c.author.username),
          },
        }
        comments.push(comment)
      }
      setComments(comments)
    } catch (e) {
      console.log("Error in onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in onError", e)
  }

  useEffect(() => {
    if (!firebase || !photo?.id) return
    const unsubscribe = firebase.firestore
      .collection("photos")
      .doc(photo.id)
      .collection("comments")
      .orderBy("createdAt", "desc")
      .onSnapshot(onSuccess, onError)
    firebase.subscriptions.push(unsubscribe)

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [firebase, photo?.id])

  return comments
}
