import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { useFirebase, useBookings, useActivities, useUser } from "@hooks"
import { authenticate, unauthenticate } from "@actions"

export const useAuth = () => {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const userSubscription = useUser()
  const activitiesSubscription = useActivities()
  const bookingsSubscription = useBookings()

  useEffect(() => {
    if (!firebase) return

    const unsubscribe = firebase.onAuthUserListener(
      async authUser => {
        try {
          await dispatch(authenticate({ currentUser: authUser }))

          userSubscription(authUser)
          bookingsSubscription(authUser)
          activitiesSubscription(authUser)

          const token = await firebase.auth.currentUser?.getIdToken(true)
          if (token) {
            localStorage.setItem("token", token)
          }
        } catch (e) {
          console.log("Error setting token")
        }
      },
      async () => {
        try {
          // Clear redux
          await dispatch(unauthenticate({}))

          localStorage.removeItem("token")
        } catch (e) {
          console.log("Error clearing token")
        }
      }
    )
    firebase.subscriptions.push(unsubscribe)

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [firebase])
}
