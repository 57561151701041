import React from "react"
import classnames from "classnames"
import CookieConsentBanner from "react-cookie-consent"

import { useTranslation } from "@hooks"

import styles from "./styles.module.scss"

const CookieConsent = () => {
  const [t] = useTranslation()
  return (
    <CookieConsentBanner
      disableStyles
      enableDeclineButton
      location={"none"}
      buttonClasses={"button"}
      containerClasses={classnames(
        styles.CookieConsent,
        "message",
        "has-background-black",
        "is-radiusless",
        "has-text-centered",
        "pb-4"
      )}
      contentClasses={classnames(
        "message-body",
        "is-borderless",
        "is-radiusless",
        "has-text-white"
      )}
      declineButtonClasses={"button is-black"}
      buttonWrapperClasses={classnames("buttons", "is-justify-content-center")}
      buttonText={t("components.CookieConsent.accept")}
      declineButtonText={t("components.CookieConsent.decline")}
      cookieName={"cookie"}
    >
      {t("components.CookieConsent.text")}
    </CookieConsentBanner>
  )
}

export default CookieConsent
