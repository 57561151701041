import axios from "axios"

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    config.headers.Authorization = token
    return config
  },
  error => Promise.reject(error)
)

/**
 * https://github.com/axios/axios/issues/960#issuecomment-320659373
 *
 * Whenever error.message is accessed we want to se the full API error message (JSON) if it's present
 * not just some generic http status code + message
 * see https://github.com/axios/axios/issues/960 for context
 *
 * @param axios
 */
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    error.originalMessage = error.message
    Object.defineProperty(error, "message", {
      get: function () {
        if (!error.response) {
          return error.originalMessage
        }
        return JSON.stringify(error.response.data)
      },
    })
    return Promise.reject(error)
  }
)

export default axios
