import { get } from "lodash"

export const selectCurrentUser = state => state.auth.currentUser

export const selectBookings = state => state.data.bookings

export const selectActivities = state => state.data.activities

// Filter related
export const selectStyle = state => get(state, "ui.filters.search", {})
export const selectType = state => get(state, "ui.filters.search", {})

export const selectCurrentLocation = state => get(state, "ui.currentLocation")
