import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { useFirebase } from "./useFirebase"
import { authenticate } from "@actions"

export const useUser = () => {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const onSuccess = async doc => {
    try {
      if (doc.exists) {
        const user: any = { ...doc.data(), id: doc.id }
        delete user.resetPasswordExpires
        delete user.resetPasswordToken
        await dispatch(authenticate({ currentUser: user }))
      }
    } catch (e) {
      console.log("Error in useUser onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in useUser onError", e)
  }

  const userSubscription = useCallback(
    user => {
      if (!firebase || !user.uid) return

      const unsubscribe = firebase.firestore
        .collection("users")
        .doc(user.uid)
        .onSnapshot(onSuccess, onError)
      firebase.subscriptions.push(unsubscribe)

      // return () => {
      //   if (unsubscribe) return unsubscribe()
      // }
    },
    [firebase]
  )

  return userSubscription
}
