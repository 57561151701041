import React from "react"
// import PropTypes from "prop-types"
import { Link } from "gatsby"
import classnames from "classnames"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import LogoWhite from "@components/LogoWhite"
import DownloadBanner from "@components/DownloadBanner"
// import NewsLetter from "@components/Newsletter"
import * as ROUTES from "@constants/routes"
import { URL_FACEBOOK, URL_INSTAGRAM } from "@constants"

import { useTranslation } from "@hooks"

import Hero from "react-bulma-components/lib/components/hero"
import Footer from "react-bulma-components/lib/components/footer"
import Container from "react-bulma-components/lib/components/container"
import Content from "react-bulma-components/lib/components/content"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"

import styles from "./styles.module.scss"

const FooterCustom = () => {
  const [t] = useTranslation()
  return (
    <Hero.Footer className={"has-text-centered"}>
      <DownloadBanner />
      {/* <NewsLetter /> */}
      <Footer className="has-background-black">
        <Container>
          <Columns breakpoint="mobile">
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 3,
              }}
              widescreen={{
                size: 3,
              }}
              fullhd={{
                size: 3,
              }}
            >
              <Content>
                <Heading
                  subtitle
                  size={5}
                  renderAs={"h3"}
                  className="has-text-white-bis has-text-centered-touch"
                >
                  {t("components.Footer.headers.explore")}
                </Heading>
              </Content>
              <ul className={"has-text-centered-touch"}>
                <li>
                  <Link to={ROUTES.TATTOOS} className="has-text-grey-light">
                    {t("components.Footer.links.tattoos")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.ARTISTS} className="has-text-grey-light">
                    {t("components.Footer.links.artists")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.STUDIOS} className="has-text-grey-light">
                    {t("components.Footer.links.studios")}
                  </Link>
                </li>
              </ul>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 3,
              }}
              widescreen={{
                size: 3,
              }}
              fullhd={{
                size: 3,
              }}
            >
              <Content>
                <Heading
                  subtitle
                  size={5}
                  renderAs={"h3"}
                  className="has-text-white-bis has-text-centered-touch"
                >
                  {t("components.Footer.headers.about")}
                </Heading>
              </Content>
              <ul className={"has-text-centered-touch"}>
                <li>
                  <Link to={ROUTES.APP} className="has-text-grey-light">
                    {t("components.Footer.links.app")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.ARTICLES} className="has-text-grey-light">
                    {t("components.Footer.links.articles")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.FAQS} className="has-text-grey-light">
                    {t("components.Footer.links.faqs")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.SELL} className="has-text-grey-light">
                    {t("components.Footer.links.sell")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.ADD} className="has-text-grey-light">
                    {t("components.Footer.links.add")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={ROUTES.BOOK_A_TATTOO}
                    className="has-text-grey-light"
                  >
                    {t("components.Footer.links.book")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.TEAM} className="has-text-grey-light">
                    {t("components.Footer.links.team")}
                  </Link>
                </li>
              </ul>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 3,
              }}
              widescreen={{
                size: 3,
              }}
              fullhd={{
                size: 3,
              }}
            >
              <Content>
                <Heading
                  subtitle
                  size={5}
                  renderAs={"h3"}
                  className="has-text-white-bis has-text-centered-touch"
                >
                  {t("components.Footer.headers.legals")}
                </Heading>
              </Content>
              <ul className={"has-text-centered-touch"}>
                <li>
                  <Link to={ROUTES.TERMS} className="has-text-grey-light">
                    {t("components.Footer.links.terms")}
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.PRIVACY} className="has-text-grey-light">
                    {t("components.Footer.links.privacy")}
                  </Link>
                </li>
              </ul>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 3,
              }}
              widescreen={{
                size: 3,
              }}
              fullhd={{
                size: 3,
              }}
            >
              <Content>
                <Heading
                  subtitle
                  size={5}
                  renderAs={"h3"}
                  className="has-text-white-bis has-text-centered-touch"
                >
                  {t("components.Footer.headers.social")}
                </Heading>
              </Content>
              <ul className={"has-text-centered-touch"}>
                <li>
                  <a href={URL_FACEBOOK} target={"_blank"}>
                    <FontAwesomeIcon
                      color={"#1c1c1e"}
                      icon={["fab", "facebook"]}
                      size={"lg"}
                      className={classnames(
                        styles.Footer__socialIcon,
                        "has-text-grey-light"
                      )}
                    />
                    <span className={classnames("has-text-grey-light")}>
                      @{t("components.Footer.social.facebook")}
                    </span>
                  </a>
                </li>
                <li className={"is-align-items-center"}>
                  <a href={URL_INSTAGRAM} target={"_blank"}>
                    <FontAwesomeIcon
                      color={"#1c1c1e"}
                      icon={["fab", "instagram"]}
                      size={"lg"}
                      className={classnames(
                        styles.Footer__socialIcon,
                        "has-text-grey-light"
                      )}
                    />
                    <span className={classnames("has-text-grey-light")}>
                      @{t("components.Footer.social.instagram")}
                    </span>
                  </a>
                </li>
              </ul>
            </Columns.Column>
          </Columns>
        </Container>
        <Section>
          <Container>
            <Content className={"has-text-centered-touch"}>
              <LogoWhite
                alt={"Logo Footer"}
                className={classnames(
                  styles.Footer__logo,
                  "has-text-centered-touch"
                )}
              />
              <p
                className={classnames(
                  styles.Footer__companyName,
                  "has-text-grey-light"
                )}
              >
                © {new Date().getFullYear()}{" "}
                {t("components.Footer.company.name")}
              </p>
              <p className={classnames("has-text-grey-light")}>
                {t("components.Footer.company.address")}
              </p>
            </Content>
          </Container>
        </Section>
      </Footer>
    </Hero.Footer>
  )
}

FooterCustom.propTypes = {}

FooterCustom.defaultProps = {}

export default FooterCustom
