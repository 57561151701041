import { useCallback, useState } from "react"
import { mergeDeepRight } from "ramda"
import { get, set } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import {
  USERS_ROLE_ARTIST,
  USERS_ROLE_CUSTOMER,
  USERS_ROLE_STUDIO,
} from "../constants"
import { bookingsIndex } from "@actions"

import { useFirebase } from "../hooks"
import { selectBookings } from "@selectors"

export const useBookings = () => {
  // TODO: Move to selector
  const bookingsFromState = useSelector(selectBookings) || {}
  const dispatch = useDispatch()
  const [bookings, setBookings] = useState(bookingsFromState)
  const firebase = useFirebase()

  // TODO: Could DRY into own component
  const getReference = async ref => {
    const doc = await ref.get()
    if (!doc.exists) return
    return { id: doc.id, ...doc.data() }
  }

  // TODO: Could DRY into own component
  const populate = async (document, paths = []) =>
    Promise.all(
      paths.map(async path => {
        const id = get(document, path)
        if (!id || typeof id !== "string") return
        const ref = firebase.firestore.collection("users").doc(id)
        const result = await getReference(ref)
        set(document, path, result)
      })
    )

  const onSuccess = async snapshot => {
    try {
      const bookingsLocal = []
      for (const doc of snapshot.docs) {
        const booking: any = { ...doc.data(), id: doc.id }
        await populate(booking, [
          USERS_ROLE_ARTIST,
          USERS_ROLE_CUSTOMER,
          USERS_ROLE_STUDIO,
        ])
        bookingsLocal[booking.id] = booking
      }
      const updatedBookings = mergeDeepRight(bookings, bookingsLocal)
      setBookings(updatedBookings)
      dispatch(bookingsIndex({ bookings: updatedBookings }))
    } catch (e) {
      console.log("Error in useBookings onSuccess", e)
    }
  }

  const onError = e => {
    console.log("Error in useBookings onError", e)
  }

  // Error in useBookings onError FirebaseError: Missing or insufficient permissions.
  const bookingsSubscription = useCallback(
    user => {
      if (!firebase || !user?.uid) return

      const unsubscribeArtist = firebase.firestore
        .collection("bookings")
        .where(USERS_ROLE_ARTIST, "==", user.uid)
        .onSnapshot(onSuccess, onError)
      firebase.subscriptions.push(unsubscribeArtist)
      const unsubscribeCustomer = firebase.firestore
        .collection("bookings")
        .where(USERS_ROLE_CUSTOMER, "==", user.uid)
        .onSnapshot(onSuccess, onError)
      firebase.subscriptions.push(unsubscribeCustomer)
      const unsubscribeStudio = firebase.firestore
        .collection("bookings")
        .where(USERS_ROLE_STUDIO, "==", user.uid)
        .onSnapshot(onSuccess, onError)
      firebase.subscriptions.push(unsubscribeStudio)

      // return () => {
      //   if (unsubscribeArtist) return unsubscribeArtist()
      //   if (unsubscribeCustomer) return unsubscribeCustomer()
      //   if (unsubscribeStudio) return unsubscribeStudio()
      // }
    },
    [firebase]
  )

  return bookingsSubscription
}
