import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classnames from "classnames"
import { useSelector } from "react-redux"

import * as ROUTES from "@constants/routes"
// import LogoWhite from "@components/LogoWhite"
import Logo from "@components/Logo"
import { useFirebase, useTranslation } from "@hooks"
import { selectCurrentUser } from "@selectors"
import { GlobalContext } from "@components/Layout"

import Navbar from "react-bulma-components/lib/components/navbar"
import Container from "react-bulma-components/lib/components/container"
import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const LoggedInNavigation = ({ user, firebase }) => {
  const [t] = useTranslation()
  return (
    <Navbar.Container position="end">
      {/* 
        <Navbar.Item renderAs={Link} className={classnames(
            "has-text-white-bis",
            "has-text-centered-tablet"
          )} to={ROUTES.CHANGE_PASSWORD}>
          Change Password
        </Navbar.Item> 
      */}
      <Navbar.Item
        renderAs={Link}
        className={classnames("has-text-white-bis", "has-text-centered-tablet")}
        to={ROUTES.ACTIVITIES}
      >
        {t("components.Header.navigation.activities")}
      </Navbar.Item>
      <Navbar.Item
        renderAs={Link}
        className={classnames("has-text-white-bis", "has-text-centered-tablet")}
        to={ROUTES.BOOKINGS}
      >
        {t("components.Header.navigation.bookings")}
      </Navbar.Item>
      <Navbar.Item
        renderAs={Link}
        className={classnames("has-text-white-bis", "has-text-centered-tablet")}
        to={ROUTES.ACCOUNT}
      >
        {user.username}
      </Navbar.Item>
      <Navbar.Item renderAs={"div"}>
        <Button
          color={"danger"}
          onClick={firebase ? firebase.doSignOut : () => {}}
          rounded
        >
          {t("components.Header.navigation.signUp")}
        </Button>
      </Navbar.Item>
    </Navbar.Container>
  )
}

const LoggedOutNavigation = ({ firebase }) => {
  const [t] = useTranslation()
  return (
    <GlobalContext.Consumer>
      {({ loginModal, registerModal }) => (
        <Navbar.Container position="end">
          {/* <Navbar.Item
            renderAs={"a"}
            className={classnames(
              "has-text-white-bis",
              "has-text-centered-tablet"
            )}
            onClick={loginModal}
          >
            {t("components.Header.navigation.login")}
          </Navbar.Item>
          <Navbar.Item
            renderAs={"a"}
            className={classnames(
              "has-text-white-bis",
              "has-text-centered-tablet"
            )}
            onClick={registerModal}
          >
            <Button
              color={"primary"}
              onClick={firebase ? firebase.doSignOut : () => {}}
              rounded
            >
              {t("components.Header.navigation.register")}
            </Button>
          </Navbar.Item> */}
        </Navbar.Container>
      )}
    </GlobalContext.Consumer>
  )
}

const Navigation = () => {
  const firebase = useFirebase()
  const currentUser = useSelector(selectCurrentUser)

  return currentUser ? (
    <LoggedInNavigation user={currentUser} firebase={firebase} />
  ) : (
    <LoggedOutNavigation firebase={firebase} />
  )
}

const Header = ({ siteTitle }) => {
  const [t] = useTranslation()
  const [active, setActive] = useState(false)
  const handleClick = () => setActive(!active)

  return (
    <header className={styles.Header}>
      <Navbar
        active={active}
        className={classnames(
          "is-fixed-top",
          active ? "has-background-black" : "has-background-white"
        )}
      >
        <Container>
          <Navbar.Brand>
            <Navbar.Item
              renderAs={Link}
              className={styles.Header__logo}
              to={ROUTES.HOME}
            >
              <Logo alt={siteTitle} className={styles.Header__logoImg} />
            </Navbar.Item>
            <Navbar.Burger
              ariaLabel={t("components.Header.navigation.menu")}
              className={classnames(
                active ? "has-text-white" : "has-text-black",
                "has-text-centered-tablet"
              )}
              onClick={handleClick}
            />
          </Navbar.Brand>
          <Navbar.Menu
            className={classnames(
              active ? "has-background-black" : "has-background-white"
            )}
          >
            <Navbar.Container>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.TATTOOS}
              >
                {t("components.Header.navigation.search")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.APP}
              >
                {t("components.Header.navigation.app")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.ARTICLES}
              >
                {t("components.Header.navigation.articles")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.FAQS}
              >
                {t("components.Header.navigation.faqs")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.SELL}
              >
                {t("components.Header.navigation.sell")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.ADD}
              >
                {t("components.Header.navigation.add")}
              </Navbar.Item>
              <Navbar.Item
                renderAs={Link}
                className={classnames(
                  "has-text-white-bis",
                  "has-text-centered-tablet",
                  "is-hidden-desktop"
                )}
                to={ROUTES.TEAM}
              >
                {t("components.Header.navigation.team")}
              </Navbar.Item>
            </Navbar.Container>
            {/* Rest of the navigation */}
            <Navigation />
          </Navbar.Menu>
        </Container>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
