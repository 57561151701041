// References:
// - https://www.youtube.com/watch?v=GVDiw3lAyp0

import { useState, useEffect, useRef, useCallback } from "react"

// Added use
// Can't call "this.setState" on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.

export const useIntersect = ({
  load,
  root = null, // window by default
  rootMargin = "0px",
  threshold = 1.0,
}) => {
  const [loading, setLoading] = useState(false)
  const fetchMore = useRef(load)
  const observer = useRef(null)

  // Using ref
  // const element = useRef(null)

  // Using state
  const [element, setElement] = useState(null)

  const loadMore = useCallback(
    entries => {
      const target = entries[0]
      if (target.isIntersecting && typeof fetchMore.current === "function") {
        setLoading(true)
        fetchMore.current()
        setLoading(false)
      }
    },
    [fetchMore]
  )

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(loadMore, {
      root,
      rootMargin,
      threshold,
    })

    const currentElement = element
    const currentObserver = observer.current
    if (currentElement) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) currentObserver.unobserve(currentElement)
      // Is this necessary?
      if (observer.current) observer.current.disconnect()
    }
  }, [element, root, rootMargin, threshold, loadMore])

  useEffect(() => {
    fetchMore.current = load
  }, [load])

  return [setElement, loading]
}
