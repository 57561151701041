import React, { useState } from "react"
import classnames from "classnames"

// import SignInForm, { SignInFacebook, SignInEmail } from "@components/SignIn"
// import SignUpForm, {
//   SignUpLink,
//   LoginLink,
//   SignUpEmail,
// } from "@components/SignUp"
// import PasswordForgetForm, {
//   PasswordForgetLink,
// } from "@components/PasswordForget"
import { FontAwesomeIcon } from "@lib/fontAwesome"
import DownloadApple from "@components/DownloadApple"
import DownloadGoogle from "@components/DownloadGoogle"
import { useTranslation } from "@hooks"

import Button from "react-bulma-components/lib/components/button"
import Heading from "react-bulma-components/lib/components/heading"
import Modal from "react-bulma-components/lib/components/modal"
import Columns from "react-bulma-components/lib/components/columns"

import styles from "./styles.module.scss"

const LoginRegister = ({ show, setShow, login, setLogin }) => {
  const [t] = useTranslation()
  const close = () => setShow(false)

  // Show/Hide login or Password reset form
  const [showForm, setShowForm] = useState("")
  // const openForm = string => setShowForm(string)
  const closeForm = () => setShowForm("")

  return (
    <div>
      <Modal
        show={show}
        onClose={close}
        closeOnBlur={true}
        className={classnames("has-text-centered", styles.Modal)}
        showClose={false}
      >
        <Modal.Content
          className={classnames("has-background-white", styles.Modal__content)}
        >
          {showForm && (
            <Button
              onClick={closeForm}
              pull={"left"}
              className={styles.Modal__btnBack}
            >
              <FontAwesomeIcon
                color={"#FFF"}
                icon={"arrow-left"}
                size={"xs"}
                className="has-text-white-bis"
              />
            </Button>
          )}
          <Button remove onClick={close} pull={"right"} />
          {/* <div className={styles.Modal__loginRegister}>
            {login ? (
              <>
                <Heading className={"has-text-black"}>
                  {t("components.LoginRegister.headings.login")}
                </Heading>
                <div className={styles.Modal__login}>
                  {!!showForm ? (
                    <>
                      {showForm === "email" && <SignInForm close={close} />}
                      {showForm === "forgot" && (
                        <PasswordForgetForm close={close} />
                      )}
                    </>
                  ) : (
                    <>
                      <SignInEmail
                        onSubmit={e => {
                          e.preventDefault()
                          openForm("email")
                        }}
                      />
                      <SignInFacebook close={close} />
                    </>
                  )}
                </div>
                <PasswordForgetLink
                  onPress={e => {
                    e.preventDefault()
                    openForm("forgot")
                  }}
                />
                <SignUpLink
                  onPress={e => {
                    e.preventDefault()
                    setLogin(false)
                  }}
                />
              </>
            ) : (
              <>
                <Heading className={"has-text-black"}>
                  {t("components.LoginRegister.headings.register")}
                </Heading>
                <div className={styles.Modal__register}>
                  {!!showForm ? (
                    <>{showForm === "email" && <SignUpForm close={close} />}</>
                  ) : (
                    <>
                      <SignUpEmail
                        onSubmit={e => {
                          e.preventDefault()
                          openForm("email")
                        }}
                      />

                      <SignInFacebook close={close} />
                    </>
                  )}
                </div>
                <LoginLink
                  onPress={e => {
                    e.preventDefault()
                    setLogin(true)
                  }}
                />
              </>
            )}
          </div> */}
          <div className={styles.Modal__download}>
            <Heading className={"has-text-black"}>
              {t("components.LoginRegister.headings.download")}
            </Heading>
            <Columns breakpoint="mobile">
              <Columns.Column
                mobile={{
                  size: 5,
                  offset: 1,
                }}
                tablet={{
                  size: 5,
                  offset: 1,
                }}
                desktop={{
                  size: 4,
                  offset: 2,
                }}
                className="has-text-centered"
              >
                <DownloadApple />
              </Columns.Column>
              <Columns.Column
                mobile={{
                  size: 5,
                }}
                tablet={{
                  size: 5,
                }}
                desktop={{
                  size: 4,
                }}
                className="has-text-centered"
              >
                <DownloadGoogle />
              </Columns.Column>
            </Columns>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default LoginRegister
