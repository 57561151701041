import { useEffect, useState } from "react"

import getFirebase from "@lib/firebase"

export const useFirebase = () => {
  const [firebase, setFirebase] = useState(null)

  useEffect(() => {
    setFirebase(getFirebase())
  }, [])

  return firebase
}
