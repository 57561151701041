import React from "react"

import DownloadApple from "@components/DownloadApple"
import DownloadGoogle from "@components/DownloadGoogle"

import { useLocation } from "@reach/router"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"

const DownloadBanner = () => {
  const { pathname } = useLocation()

  if (pathname === "/") return null

  return (
    <Section className={"has-background-white-bis"}>
      <Container>
        <Columns breakpoint="mobile" centered={true} className={"is-vcentered"}>
          <Columns.Column
            mobile={{
              size: 4,
            }}
            tablet={{
              size: 3,
            }}
            desktop={{
              size: 3,
            }}
          >
            <DownloadApple />
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 4,
            }}
            tablet={{
              size: 3,
            }}
            desktop={{
              size: 3,
            }}
          >
            <DownloadGoogle />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default DownloadBanner
