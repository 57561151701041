import { useState, useEffect } from "react"
// import { navigate } from "@reach/router"

import {
  createURL,
  urlToSearchState,
  searchStateToUrl,
  DEBOUNCE_TIME,
} from "@lib/history"

export const useHistory = location => {
  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const [lastLocation, setLastLocation] = useState(location)
  let debouncedSetState

  useEffect(() => {
    if (location !== lastLocation) {
      setSearchState(urlToSearchState(location))
      setLastLocation(location)
    }
  }, [location, lastLocation])

  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetState)

    debouncedSetState = setTimeout(() => {
      // searchState looks like ---> { page, indicies, configure }
      const { page } = searchState
      const updatedUrl = searchStateToUrl({ location }, { page })

      // Update url
      window.history.pushState({ page }, null, updatedUrl)
      // navigate(updatedUrl)
    }, DEBOUNCE_TIME)

    setSearchState(searchState)
  }

  return [onSearchStateChange, searchState, createURL]
}
