import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const Logo = ({ alt, style, imgStyle, className }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "iynk-logo-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            aspectRatio
            # ...GatsbyImageSharpFluid // With blur-up
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.logo.childImageSharp.fluid}
      style={style} /* styles for the wrapper container */
      imgStyle={imgStyle} /* Styles for the actual img element */
      alt={alt}
      className={className}
    />
  )
}

Logo.defaultProps = {
  style: {},
  imgStyle: {},
  alt: "The IYNK logo",
}

Logo.propTypes = {
  style: PropTypes.object.isRequired,
  imgStyle: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Logo
